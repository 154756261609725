import React, { memo, useCallback, useEffect, useState } from 'react'
import { ACTIONS, STATUS, EVENTS } from 'react-joyride'
import { useIntl } from 'react-intl'
import { message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import {
  mobileViewSelector,
  loadedInitSelector,
  optionsSelector,
  tutorialSelector,
} from 'redux/selectors'
import { HelpDropdown, ModalPlayer, ProductTour } from 'components/UI'
import Player from 'components/UI/ModalPlayer/Player'
import { getTutorial } from 'utils/apis'
import { editOption } from 'redux/options/actions'
import { compareWithJSON } from 'utils/helper'

const Module = ({ name, joyrideCallback, joyrideRef, hideOnEmpty, ...props }) => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const tutorial = useSelector((state) => tutorialSelector(state, name))
  const isMobileView = useSelector(mobileViewSelector)
  const loadedInit = useSelector(loadedInitSelector)
  const options = useSelector(optionsSelector)

  const [visiblePlayer, setVisiblePlayer] = useState(false)
  const [run, setRun] = useState(false)
  const [stepIndex, setStepIndex] = useState(0)
  const [steps, setSteps] = useState([])

  useEffect(() => {
    if (loadedInit && tutorial?.name && options[`tutorial_${name}`]) {
      openTourHandler()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedInit])

  const joyrideCallbackHandler = useCallback(
    (data) => {
      const { action, status, index, type } = data
      if (action === ACTIONS.CLOSE || [STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
        setRun(false)
        if (options[`tutorial_${name}`]) {
          dispatch(editOption({ [`tutorial_${name}`]: 0 }))
        }
      } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
        const stepI = index + (action === ACTIONS.PREV ? -1 : 1)
        setStepIndex(stepI)
      }
      if (typeof joyrideCallback === 'function') {
        joyrideCallback(data, { stepIndex, setStepIndex, run, setRun })
      }
    },
    [dispatch, joyrideCallback, name, options, run, stepIndex],
  )

  const getData = useCallback(async () => {
    try {
      const response = await getTutorial(name)
      if (response.data.success) {
        setSteps(formatSteps(response.data.data))
        setStepIndex(0)
        setTimeout(() => {
          setRun(true)
        }, 100)
      }
    } catch (error) {
      message.error((error && error.message) || formatMessage({ id: 'button.error_data_' }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTutorial, name])

  const formatSteps = useCallback((data) => {
    const newSteps = data.map((row) => {
      const newRow = { ...row }
      if (row.videoId) {
        newRow.content = (
          <>
            <div dangerouslySetInnerHTML={{ __html: newRow.content }} />
            <Player
              containerClassName="video-responsive"
              className="youtube-player mt-4"
              videoId={row.videoId}
              options={{
                playerVars: {
                  autoplay: 0,
                },
              }}
            />
          </>
        )
      } else {
        newRow.content = <div dangerouslySetInnerHTML={{ __html: row.content }} />
      }
      return newRow
    })
    return newSteps
  }, [])

  const showPlayerHandler = () => setVisiblePlayer(true)
  const hidePlayerHandler = () => setVisiblePlayer(false)
  const openArticle = () => window.open(tutorial?.article, '_blank')
  const openTourHandler = useCallback(() => {
    if (steps.length === 0) {
      getData()
    } else {
      setStepIndex(0)
      setRun(true)
    }
  }, [steps, getData])

  if (hideOnEmpty && Object.keys(tutorial).length === 0) return null

  return (
    <>
      <HelpDropdown
        onClickTour={tutorial?.name ? openTourHandler : undefined}
        onClickVideo={tutorial?.video_id ? showPlayerHandler : undefined}
        onClickArticle={tutorial?.article ? openArticle : undefined}
        isMobileView={isMobileView}
        {...props}
      />
      {visiblePlayer && (
        <ModalPlayer
          title={formatMessage({ id: 'tutorial.video_tutorial' })}
          visible={visiblePlayer}
          onCancel={hidePlayerHandler}
          videoId={tutorial?.video_id}
        />
      )}

      <ProductTour
        ref={joyrideRef}
        callback={joyrideCallbackHandler}
        run={run}
        steps={steps}
        spotlightPadding={8}
        stepIndex={stepIndex}
      />
    </>
  )
}

export default memo(Module, compareWithJSON)
