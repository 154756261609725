import { Image, Modal } from 'antd'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { bannerSelector } from 'redux/selectors'
import Cookies from 'js-cookie' // Tambahkan pustaka js-cookie

const Banner = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const banner = useSelector(bannerSelector)

  const handleClose = useCallback(() => {
    setIsModalVisible(false)
    // Simpan waktu modal ditutup ke cookies (format ISO 8601)
    Cookies.set('banner_closed', dayjs().toISOString(), { expires: 1 / 24 }) // Berlaku 1 jam (1/24 hari)
  }, [])

  const handleBannerClick = useCallback(() => {
    if (banner.url) {
      window.open(banner.url, '_blank')
    }
  }, [banner])

  useEffect(() => {
    if (banner.id) {
      // Konversi waktu dari WIB ke UTC
      const startTimeUTC = dayjs.tz(banner.start_time, 'Asia/Jakarta').utc() // WIB ke UTC
      const endTimeUTC = dayjs.tz(banner.end_time, 'Asia/Jakarta').utc() // WIB ke UTC

      // Waktu lokal pengguna
      const nowLocal = dayjs().tz(dayjs.tz.guess())

      // Konversi waktu UTC ke waktu lokal
      const startTimeLocal = startTimeUTC.local()
      const endTimeLocal = endTimeUTC.local()

      // Periksa jika modal sudah ditutup (berdasarkan cookie)
      const modalClosedTime = Cookies.get('banner_closed')

      if (!modalClosedTime) {
        if (nowLocal.isAfter(startTimeLocal) && nowLocal.isBefore(endTimeLocal)) {
          setIsModalVisible(true)
        }
      } else {
        const lastClosedTime = dayjs(modalClosedTime)
        const oneHourLater = lastClosedTime.add(1, 'hour')

        if (nowLocal.isAfter(oneHourLater)) {
          setIsModalVisible(true)
        }
      }
    }
  }, [banner])

  return (
    <Modal
      styles={{ content: { padding: 0 } }}
      open={isModalVisible}
      onCancel={handleClose}
      footer={null}
    >
      <Image
        src={banner.banner_url}
        alt={banner.title}
        preview={false}
        style={{
          width: '100%',
          height: '100%',
          cursor: 'pointer', // Gambar bisa diklik untuk diarahkan
          objectFit: 'cover', // Pastikan gambar memenuhi area
          borderRadius: 8,
        }}
        onClick={handleBannerClick}
      />
    </Modal>
  )
}

export default Banner
