import axios from 'axios'

import { cleanBlankValue, openNewTabBlobFile } from 'utils/helper'

export const getFinanceBankTranses = (params) => {
  return axios.get('/finance/bankTrans', { params })
}

export const getTotalFinanceBankTrans = (params) => {
  return axios.get('/finance/bankTrans/totals', { params })
}

export const getFinanceBankTrans = (id) => {
  return axios.get(`/finance/bankTrans/${id}`)
}

export const getFinanceBankTransLog = (id, params) => {
  return axios.get(`/finance/bankTrans/${id}/logs`, { params })
}

export const editFinanceBankTrans = (id, data) => {
  return axios.put(`/finance/bankTrans/${id}`, data)
}

export const purchasePaymentFinanceBankTrans = (data) => {
  return axios.post('/finance/bankTrans/purchasePayment', data)
}

export const invoicePaymentFinanceBankTrans = (data) => {
  return axios.post('/finance/bankTrans/invoicePayment', data)
}

export const orderPaymentFinanceBankTrans = (data) => {
  return axios.post('/finance/bankTrans/orderPayment', data)
}

export const purchaseOrderPaymentFinanceBankTrans = (data) => {
  return axios.post('/finance/bankTrans/purchaseOrderPayment', data)
}

export const creditMemoPaymentFinanceBankTrans = (data) => {
  return axios.post('/finance/bankTrans/creditMemoPayment', data)
}

export const debitMemoPaymentFinanceBankTrans = (data) => {
  return axios.post('/finance/bankTrans/debitMemoPayment', data)
}

export const deleteOnAddFinanceBankTransAttachment = (url) => {
  return axios.delete('/finance/bankTrans/attachments', { data: { url } })
}

export const deleteOnEditFinanceBankTransAttachment = (id, url) => {
  return axios.delete(`/finance/bankTrans/${id}/attachments`, { data: { url } })
}

export const deleteFinanceBankTrans = (id) => {
  return axios.delete(`/finance/bankTrans/${id}`)
}

export const transferFinanceBankTrans = (data) => {
  return axios.post('/finance/bankTrans/transfer', data)
}

export const spendFinanceBankTrans = (data) => {
  return axios.post('/finance/bankTrans/spend', data)
}

export const receiveFinanceBankTrans = (data) => {
  return axios.post('/finance/bankTrans/receive', data)
}

export const deleteTransferFinanceBankTrans = (id) => {
  return axios.delete(`/finance/bankTrans/${id}/transfer`)
}

export const editTransferFinanceBankTrans = (id, data) => {
  return axios.put(`/finance/bankTrans/${id}/transfer`, data)
}

export const expensePaymentFinanceBankTrans = (data) => {
  return axios.post('/finance/bankTrans/expensePayment', data)
}

export const editPurchasePaymentFinanceBankTrans = (id, data) => {
  return axios.put(`/finance/bankTrans/${id}/purchasePayment`, data)
}

export const editInvoicePaymentFinanceBankTrans = (id, data) => {
  return axios.put(`/finance/bankTrans/${id}/invoicePayment`, data)
}

export const editExpensePaymentFinanceBankTrans = (id, data) => {
  return axios.put(`/finance/bankTrans/${id}/expensePayment`, data)
}

export const editCreditMemoPaymentFinanceBankTrans = (id, data) => {
  return axios.put(`/finance/bankTrans/${id}/creditMemoPayment`, data)
}

export const editDebitMemoPaymentFinanceBankTrans = (id, data) => {
  return axios.put(`/finance/bankTrans/${id}/debitMemoPayment`, data)
}

export const editOrderPaymentFinanceBankTrans = (id, data) => {
  return axios.put(`/finance/bankTrans/${id}/orderPayment`, data)
}

export const editPurchaseOrderPaymentFinanceBankTrans = (id, data) => {
  return axios.put(`/finance/bankTrans/${id}/purchaseOrderPayment`, data)
}

export const createPdfFinanceBankTrans = (id) => {
  return axios
    .get(`/finance/bankTrans/${id}/print`, { responseType: 'blob' })
    .then((response) => {
      openNewTabBlobFile(response)
      return Promise.resolve(true)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export const getFinanceBankTransPublicUrl = (id) => {
  return axios.get(`/finance/bankTrans/${id}/publicUrl`)
}

export const exportFinanceBankTrans = (params) => {
  params = cleanBlankValue(params)
  return axios.get('/finance/bankTrans/export', { responseType: 'arraybuffer', params })
}

export const getFinanceBankTransWaTemplate = (id) => {
  return axios.get(`/finance/bankTrans/${id}/whatsapp`)
}

export const getFinanceBankTransEmailTemplate = (id) => {
  return axios.get(`/finance/bankTrans/${id}/email`)
}

export const sendFinanceBankTransEmail = (id, data) => {
  return axios.post(`/finance/bankTrans/${id}/email`, data)
}

export const addRecurringBankTrans = (data) => {
  return axios.post('/finance/bankTrans/recurring', data)
}

export const editRecurringBankTrans = (data) => {
  return axios.put(`/finance/bankTrans/recurring/${data.id}`, data)
}

export const getRecurringBankTrans = (id) => {
  return axios.get(`/finance/bankTrans/recurring/${id}`)
}

export const deleteRecurringBankTrans = (id) => {
  return axios.delete(`/finance/bankTrans/recurring/${id}`)
}

export const getRecurringBankTranses = (params) => {
  return axios.get(`/finance/bankTrans/recurring`, { params })
}

export const getFinanceBankTransSmsTemplate = (id) => {
  return axios.get(`/finance/bankTrans/${id}/sms`)
}

export const sendFinanceBankTransSms = (id, data) => {
  return axios.post(`/finance/bankTrans/${id}/sms`, data)
}

export const inputMassDeleteFinanceBankTrans = (data) => {
  return axios.post('/finance/bankTrans/inputMassDelete', data)
}

export const executeMassDeleteFinanceBankTrans = (data) => {
  return axios.delete('/finance/bankTrans/executeMassDelete', { data })
}

export const uploadImportFinanceBankTrans = (data) => {
  return axios.postForm('/finance/bankTrans/uploadImport', data)
}

export const executeImportFinanceBankTrans = (data) => {
  return axios.post('/finance/bankTrans/executeImport', data)
}

export const massPaymentFinanceBankTrans = (data) => {
  return axios.post('/finance/bankTrans/massPayment', data)
}

export const executeMassPaymentFinanceBankTrans = (data) => {
  return axios.post('/finance/bankTrans/executeMassPayment', data)
}

export const uploadImportFinanceBankTransfer = (data) => {
  return axios.postForm('/finance/bankTrans/transfer/uploadImport', data)
}

export const executeImportFinanceBankTransfer = (data) => {
  return axios.post('/finance/bankTrans/transfer/executeImport', data)
}

export const approveFinanceBankTransfer = (id) => {
  return axios.post(`finance/bankTrans/${id}/approve`)
}

export const rejectFinanceBankTransfer = ({ id, body }) => {
  return axios.post(`finance/bankTrans/${id}/reject`, body)
}

export const revertFinanceBankTransfer = ({ id, body }) => {
  return axios.post(`finance/bankTrans/${id}/revert`, body)
}
